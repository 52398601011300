<template>
  <v-app class="layout-error">
    <PartialsHeader>
      <template #right>
        <v-toolbar-items class="d-flex w-100 h-100 justify-end">
          <v-btn color="secondary" variant="text" @click="onLiveChatRequest">
            <component :is="liveChatTextWrapper">{{ liveChatText }}</component>
          </v-btn>
        </v-toolbar-items>
      </template>
    </PartialsHeader>
    <v-main>
      <PartialsFourOhFour />
    </v-main>
  </v-app>
</template>

<script lang="ts">
/**
 * This layout is the "error" screen
 */
import type { Bus } from '~/libs/bus'
import { defineComponent, inject, computed } from 'vue'
import { useDisplay } from 'vuetify'
import { useI18n } from 'vue-i18n'
import PartialsHeader from '~/components/partials/header.vue'
import PartialsFourOhFour from '~/components/partials/fourOhFour.vue'

export default defineComponent({
  name: 'ErrorLayout',
  components: {
    PartialsHeader,
    PartialsFourOhFour,
  },
  setup() {
    const runtimeConfig = useRuntimeConfig()
    const display = useDisplay()
    const { t } = useI18n({ useScope: 'global' })
    const xs = computed(() => display.xs.value)
    const smAndDown = computed(() => display.smAndDown.value)
    const loaderSize = computed(() => (smAndDown.value ? 100 : 100))
    const bus = inject<Bus>('bus')
    const onLiveChatRequest = () => {
      if (bus) {
        bus.emit('livechat:open', { local: true, crossTab: true })
      }
    }
    const liveChatTextWrapper = computed(() => (xs.value ? 'small' : 'span'))
    const liveChatText = computed(() =>
      smAndDown.value ? t('buttons.chat') : t('buttons.livechat')
    )
    const cellBind = computed(() => ({
      class: smAndDown.value ? ['fill-height', 'py-0'] : ['py-0'],
    }))
    const cardBind = computed(() => ({
      color: smAndDown.value ? 'background' : 'surface',
      flat: smAndDown.value,
      class: smAndDown.value
        ? ['fill-height', 'd-flex', 'flex-column', 'mb-3']
        : ['d-flex', 'flex-column', 'px-14'],
    }))
    const logoHeight = computed(() => (smAndDown.value ? 32 : 64))
    const brand = computed(() => runtimeConfig.public.brand)
    useHead(() => ({
      title: t('fourOhFour.meta.title', { brand: brand.value }),
      titleTemplate: `%s | ${t('meta.title', { brand: brand.value })}`,
      description: t('fourOhFour.meta.description', { brand: brand.value }),
    }))
    return {
      cnfg: runtimeConfig.public,
      onLiveChatRequest,
      loaderSize,
      liveChatTextWrapper,
      liveChatText,
      cellBind,
      cardBind,
      logoHeight,
    }
  },
})
</script>

<style lang="scss">
.layout-error {
  .product-text {
    text-transform: uppercase !important;
  }
}
</style>
